import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { useHistory } from "react-router-dom";
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from "../elements/Image";

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  bottomArrow,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
  );

  const history = useHistory();
  const showSignIn = () => history.push('/signin');

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-m">
        <div className={innerClasses}>
          <div className="hero-content">
            <div className={splitClasses}>
              <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                  <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                    Hear your customers, get smarter <span className="text-color-primary">insights</span> for your business
                  </h1>
                  <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                    Blurby is an AI-powered tool that interprets your customers' feedback, and empowers you to create delightful customer experiences. Tell us where your customers leave reviews, we'll tell you what they're saying.
                  </p>
                  <div className="reveal-from-bottom" data-reveal-delay="600">
                    <ButtonGroup>
                      <Button tag="a" color="primary" wideMobile onClick={showSignIn}>
                        Learn More
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
                <div className={
                  classNames(
                      'split-item-image center-content-mobile reveal-from-bottom',
                      imageFill && 'split-item-image-fill'
                  )}
                     data-reveal-container=".split-item">
                  <Image
                      src={require('./../../assets/images/System-Analysis.png')}
                      alt="Features split 01"
                      width={400}
                      height={300} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;