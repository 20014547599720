import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../utils/SectionProps';
import Form from "react-bootstrap/Form";
import { Auth } from "@aws-amplify/auth";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../utils/AppContext";

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const SignIn = ({
                  className,
                  topOuterDivider,
                  bottomOuterDivider,
                  topDivider,
                  bottomDivider,
                  bottomArrow,
                  hasBgColor,
                  invertColor,
                  ...props
              }) => {

    const { userHasAuthenticated } = useAppContext();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    function validateForm() {
        return email.length > 0 && password.length > 0;
    }

    const history = useHistory();

    const showSignUp = () => history.push('/signin/newuser');
    const showDashboard = () => history.push('dashboard');

    async function handleSubmit(event) {
        event.preventDefault();

        try {
            await Auth.signIn(email, password);
            userHasAuthenticated(true);
            showDashboard()
        } catch (e) {
            alert(e.message);
        }
    }


    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container-sm">
                <div className={innerClasses}>
                    <div className="hero-content mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                        <Form onSubmit={handleSubmit}>
                            <Form.Group size="lg" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    autoFocus
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group size="lg" controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Form.Group>
                            <button color="primary" type="submit" disabled={!validateForm()}>
                                Sign In
                            </button>
                            <button color="primary" onClick={showSignUp}>
                                Sign Up
                            </button>
                        </Form>
                    </div>
                </div>
            </div>
        </section>
    );
}

SignIn.propTypes = propTypes;
SignIn.defaultProps = defaultProps;

export default SignIn;