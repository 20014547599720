import React from 'react';
import FeaturesTiles from '../components/sections/FeaturesTiles';

const Landing = () => {

    return (
        <>
        <FeaturesTiles />
        </>
    );
}

export default Landing;