import React, { useRef, useEffect, useState } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/routes/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';
import { AppContext } from "./utils/AppContext";
import AuthenticatedRoute from "./utils/routes/AuthenticatedRoute";
import UnauthenticatedRoute from "./utils/routes/UnauthenticatedRoute";

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Landing from './views/Landing';
import SignIn from './views/SignIn';
import SignUp from './views/SignUp';
import Dashboard from "./views/Dashboard";

// Import Auth module from AWS Amplify
import { Auth } from "aws-amplify";


// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
    ReactGA.set({ page });
    ReactGA.pageview(page);
};

const App = () => {
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);

    const childRef = useRef();
    let location = useLocation();

    useEffect(() => {
        onLoad().then( res => {
            const page = location.pathname;
            document.body.classList.add('is-loaded')
            childRef.current.init();
            trackPage(page);
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    async function onLoad() {
        try {
            await Auth.currentSession();
            userHasAuthenticated(true);
        }
        catch(e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }

        setIsAuthenticating(false);
    }

    return (
        !isAuthenticating && (
            <ScrollReveal
                ref={childRef}
                children={() => (
                    <Switch>
                        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
                            <AppRoute exact path="/" component={Landing} layout={LayoutDefault} />
                            <UnauthenticatedRoute exact path="/signin" component={SignIn} layout={LayoutDefault} />
                            <UnauthenticatedRoute exact path="/signin/newuser" component={SignUp} layout={LayoutDefault} />
                            <AuthenticatedRoute exact path="/dashboard" component={Dashboard} layout={LayoutDefault} />
                        </AppContext.Provider>
                    </Switch>
                )}
            />
        )
    );
}

export default App;